import React from 'react';

import { useMobileView } from '@app/utils/useMobileView';
import SiteLinks from '@app/components/UI/SiteLinks/SiteLinks';
import { SocialMediaLinks } from '@app/objects/Location';
import Drawer from '@app/components/UI/Drawer/Drawer';

const sections = [
	{ name: 'About us', id: 'about-us' },
	{ name: 'Our Services', id: 'our-services' },
	{ name: 'Our team', id: 'our-team' },
	{ name: 'Results', id: 'cases' },
	{ name: 'Reviews', id: 'reviews' },
	{ name: 'FAQ', id: 'faq' },
	{ name: 'Blog', id: 'blog' },
	{ name: 'Contacts', id: 'footer' },
];

const BurgerMenu: React.FC<{socialMediaLinks: SocialMediaLinks}> = ({ socialMediaLinks }) => {
	const [open, setOpen] = React.useState<boolean>(false);

	const toggle = () => {
		setOpen(!open);
	};

	const isMobile = useMobileView(993);
	const placement = isMobile ? 'top' : 'left';

	return <>
		<Drawer
			placement={placement}
			onClose={toggle}
			open={open}
			width="415px"
			height="50px"
			closable={false}
			rootClassName="burger-menu__drawer"
			contentWrapperStyle={{
				height: isMobile ? 'unset' : undefined,
			}}
		>
			<div className="burger-menu__container">
				<ul className="burger-menu__list">
					{sections.map((section, index) =>
						<li key={section.name} className="burger-menu__list-item list-item" onClick={() => setOpen(false)}>
							<a href={`/#${section.id}`} className="list-item__link">
								<div className="list-item__name">
									<span className="list-item__index">
										/0
										{index + 1}
									</span>
									{section.name}
								</div>
							</a>
						</li>)
					}
				</ul>
			</div>
			<SiteLinks socialMediaLinks={socialMediaLinks} />
		</Drawer>
		<div className={`burger-menu ${open ? 'open ' : ''}`}>
			<button className="burger-menu__btn" type="button" onClick={toggle}>
				<div className={`burger-menu__icon ${open ? 'open-icon' : 'close-icon'}`}>
					<span />
					<span />
					<span />
				</div>
			</button>
		</div>
	</>;
};

export default BurgerMenu;
