import * as React from 'react';

interface Props {
	fill: string
	style?: React.CSSProperties | undefined
}

const HorizontalArrow: React.FC<Props> = ({ fill, style }) => {
	return (
		<svg
			width="41"
			height="28"
			viewBox="0 0 41 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<path
				d="M13.2242 0.0436623C12.6897 -0.0953346 12.1437 0.22529 12.0047 0.759798C11.1895 3.89469 10.3255 6.05915 8.7422 7.83368C7.22908
				9.52953 4.97783 10.9584 1.23189 12.4435C0.754707 12.3354 0.258213 12.5915 0.0782509 13.0618C0.0206451 13.2123 0.00183868 13.3682
				0.0169144 13.5183C0.00468063 13.6636 0.0241432 13.8137 0.0797157 13.959C0.259438 14.4287 0.754879 14.6847 1.23149 14.5777C4.97679
				16.0627 7.22776 17.4914 8.74074 19.1871C10.324 20.9617 11.188 23.1261 12.0032 26.261C12.1422 26.7955 12.6882 27.1162 13.2227
				26.9772C13.7572 26.8382 14.0778 26.2922 13.9388 25.7577C13.1001 22.5324 12.1387 19.9914 10.2331 17.8556C9.09895 16.5845 7.66215
				15.4919 5.79166 14.4746H39.0095C39.5618 14.4746 40.0095 14.0269 40.0095 13.4746C40.0095 12.9223 39.5618 12.4746 39.0095
				12.4746H5.92382C7.73074 11.4776 9.12697 10.4065 10.2345 9.1652C12.1401 7.02946 13.1016 4.48839 13.9403 1.26315C14.0793 0.728642
				13.7587 0.182659 13.2242 0.0436623Z"
				fill={fill}
			/>
		</svg>
	);
};

export default HorizontalArrow;
