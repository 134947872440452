import * as React from 'react';

import { BaseSpecial } from '@commonTuna/react/objects/BaseSpecial';

import BookAppointmentModal from '@app/components/UI/BookAppointmentModal/BookAppointmentModal';
import LeftPanel from '@app/components/UI/LeftPanel/LeftPanel';
import Background from '@app/components/UI/Background/Background';
import { SocialMediaLinks } from '@app/objects/Location';
import SpecialsSlider from '@app/components/UI/SpecialsSlider/SpecialsSlider';

import mainImage from '@images/MainBlock/main_block_big_image.png';

interface Props {
	socialMediaLinks: SocialMediaLinks;
	specials: Array<BaseSpecial>;
	title: string;
}

const MainBlock: React.FC<Props> = ({ socialMediaLinks, specials, title }) => {
	return <section className="main-block relative-container main-background" id="main-block">
		<Background count={5}>
			<LeftPanel socialMediaLinks={socialMediaLinks} />
			<div className="container">
				<div className="main-block__info">
					<div className="main-block__text flex-container">
						<h1>{title}</h1>
						<SpecialsSlider specials={specials} />
					</div>
					<div className="main-block__image-container flex-container">
						<div className="main-block__image">
							<BookAppointmentModal />
							<img src={mainImage} alt="Beautiful girl" />
						</div>
					</div>
				</div>
			</div>
		</Background>
	</section>;
};

export default MainBlock;
