import React from 'react';
import Slider from 'react-slick';

import { getSettings } from '@app/utils/getCarouselSettings';
import CaseModal from '@app/components/Pages/Home/CaseModal';
import { Case } from '@app/objects/Case';
import magnifier from '@app/images/magnifier.png';
import ImageWithLoader from '@app/components/UI/ImageWithLoader/ImageWithLoader';

const Cases: React.FC<{cases: Array<Case> | undefined}> = ({ cases }) => {
	const [activeCase, setActiveCase] = React.useState<Case | undefined>();
	const [open, setOpen] = React.useState<boolean>(false);

	const toggle = (): void => {
		setOpen(!open);
	};

	const onModalArrowClick = (activeCase: Case, isNext: boolean): void => {
		if (!cases) return;

		const currentIndex: number = cases.indexOf(activeCase);
		let nextIndex: number;

		if (isNext) {
			nextIndex = (currentIndex + 1) % cases.length;
		} else {
			nextIndex = currentIndex === 0 ? cases.length - 1 : currentIndex - 1;
		}

		setActiveCase(cases[nextIndex]);
	};

	const onCaseClick = (caseToShow: Case) => {
		setActiveCase(caseToShow);
		toggle();
	};

	const fullScreenSlidesToShow = cases?.length ? (cases.length < 5 ? cases.length : 5) : 0;

	return <section className="cases relative-container" id="cases">
		<h2 className="cases__title section-title text-center">Results</h2>
		<div className="container">
			<div>
				<Slider
					dots={false}
					slidesToShow={fullScreenSlidesToShow}
					slidesToScroll={1}
					className="cases-carousel"
					arrows
					responsive={[getSettings(992, 2, cases?.length ?? 0)]}
					lazyLoad="ondemand"
				>
					{cases?.map((c: Case, index) => <React.Fragment key={index}>
						<div key={c.id} className="cases-carousel__case" onClick={() => onCaseClick(c)}>
							<div className="cases-carousel__case-overlay">
								<img src={magnifier} alt="magnifier" />
							</div>
							<ImageWithLoader
								className="case-before"
								src={c.before}
								alt="before"
								uniqueKey={`before-${c.id}`}
								containerClassName="case-before"
							/>
							<ImageWithLoader
								className="case-after"
								src={c.after}
								alt="after"
								uniqueKey={`after-${c.id}`}
								containerClassName="case-after"
							/>
						</div>
					</React.Fragment>)}
				</Slider>
			</div>
		</div>
		{activeCase && <CaseModal open={open} toggle={toggle} caseToShow={activeCase} onModalArrowClick={onModalArrowClick} />}
	</section>;
};

export default Cases;
