import React from 'react';

import imageSkeleton from '@commonTuna/react/images/no-image-available.jpg';

const AboutUs: React.FC<{description: string, avatar: string}> = ({ description, avatar }) => {
	return <section className="about-us" id="about-us">
		<div className="about-us__image">
			<img src={avatar ? `/remote/${avatar}` : imageSkeleton} alt="Avatar" />
		</div>
		<div className="about-us__info">
			<h2 className="about-us__title section-title">About us</h2>
			<div dangerouslySetInnerHTML={{ __html: description }} className="about-us__text" />
		</div>
	</section>;
};

export default AboutUs;
