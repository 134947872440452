import React from 'react';

import { Lang } from '@common/typescript/objects/Lang';

import Background from '@app/components/UI/Background/Background';
import { useMobileView } from '@app/utils/useMobileView';
import { Page } from '@app/objects/Page';

import servicesImage from '@images/Services/services.jpg';

const Services: React.FC<{servicePages: Array<Page>}> = ({ servicePages }) => {
	const isMobile = useMobileView(1500);

	const getShortDescription = (service: Page, isMobile) => {
		const TruncHtml = require('trunc-html');
		const description = `<h3>${service.name}</h3>`.concat(service?.langs?.find((el) => el.language === Lang.En)?.shortBody ?? '');
		const truncDescription = TruncHtml(description, 175).html;

		return isMobile ? description : truncDescription;
	};

	return <section className="services relative-container" id="our-services">
		<Background count={5}>
			<div className="container">
				<h2 className="services__title section-title">Our Services</h2>
				<ul className="services__list">
					<img src={servicesImage} alt="Beautiful women" className="services__img" />
					{servicePages.map((service) => <li key={service.id} className="services__list-item">
						<div dangerouslySetInnerHTML={{ __html: getShortDescription(service, isMobile) }} className="description" />
					</li>)}
				</ul>
			</div>
		</Background>
	</section>;
};

export default Services;
