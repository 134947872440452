import React from 'react';
import Slider from 'react-slick';

import { Rows } from '@common/typescript/objects/List';

import Background from '@app/components/UI/Background/Background';
import ImageWithLoader from '@app/components/UI/ImageWithLoader/ImageWithLoader';
import { Article } from '@app/objects/Article';
import { getSettings } from '@app/utils/getCarouselSettings';
import MoreButton from '@app/components/UI/MoreButton/MoreButton';

const BlogSection: React.FC<{articles: Rows<Article>}> = ({ articles }) => {
	const articlesLength = articles.list?.length;
	const fullScreenSlidesToShow = articlesLength < 4 ? articlesLength : 4;

	return <section className="blog relative-container" id="blog">
		<Background count={5}>
			<div className="container">
				<h2 className="blog__title section-title">Blog</h2>
				<MoreButton to="/blog" visible={articlesLength < articles.count} />
				<div>
					<Slider
						dots={false}
						slidesToShow={fullScreenSlidesToShow}
						slidesToScroll={1}
						className="blog-carousel"
						responsive={[getSettings(1500, 3, articlesLength), getSettings(821, 2, articlesLength)]}
						arrows
						lazyLoad="ondemand"
					>
						{articles.list?.map((article) =>
							<React.Fragment key={article.id}>
								<ImageWithLoader
									containerClassName="blog-carousel__item"
									className="blog-carousel__img"
									viewBox="0 0 400 600"
									rectProps={{ rx: '300', ry: '300' }}
									src={article.originalAvatar ?? ''}
									alt={article.name}
									uniqueKey={article.id.toString()}
								/>
								<h3>{article.shortNameEn}</h3>
							</React.Fragment>)}
					</Slider>
				</div>
			</div>
		</Background>
	</section>;
};

export default BlogSection;
