import React from 'react';
import Slider from 'react-slick';

import { Doctor } from '@app/objects/Doctor';
import BookAppointmentModal from '@app/components/UI/BookAppointmentModal/BookAppointmentModal';
import ImageWithLoader from '@app/components/UI/ImageWithLoader/ImageWithLoader';
import LeftArrow from '@app/components/UI/ArrowButtons/LeftArrow';
import RightArrow from '@app/components/UI/ArrowButtons/RightArrow';

interface Props {
	doctors?: Array<Doctor>;
}

const Doctors: React.FC<Props> = ({ doctors = [] }) => {
	const ref = React.useRef<Slider>(null);

	const nextDoctor = () => {
		ref.current?.slickNext();
	};

	const previousDoctor = () => {
		ref.current?.slickPrev();
	};

	return <section className="doctors" id="our-team">
		<div className="container">
			<h2 className="section-title doctors-carousel__title">Our team</h2>
			<Slider
				ref={ref}
				dots={false}
				slidesToShow={1}
				slidesToScroll={1}
				className="doctors-carousel"
				arrows={false}
				fade
				lazyLoad="ondemand"
			>
				{doctors.map((doctor) => <React.Fragment key={doctor.id}>
					<div className="doctors-carousel__item">
						<div className="doctors-carousel__left">
							<BookAppointmentModal />
							<ImageWithLoader
								containerClassName="doctor-slide"
								className="doctor-slide__img"
								viewBox="0 0 400 646"
								rectProps={{ rx: '200', ry: '200' }}
								src={doctor.originalAvatar}
								alt={doctor.nameEn}
								uniqueKey={doctor.id.toString()}
							/>
							{doctors?.length > 1 && (
								<>
									<LeftArrow onClick={previousDoctor} />
									<RightArrow onClick={nextDoctor} />
								</>
							)}
						</div>
						<div className="doctors-carousel__right">
							<h3>{doctor.nameEn}</h3>
							<h4 dangerouslySetInnerHTML={{ __html: doctor.descriptionEn }} />
						</div>
					</div>
				</React.Fragment>)}
			</Slider>
		</div>
	</section>;
};

export default Doctors;
