import * as React from 'react';

import { Case } from '@app/objects/Case';
import LeftArrow from '@app/components/UI/ArrowButtons/LeftArrow';
import RightArrow from '@app/components/UI/ArrowButtons/RightArrow';
import Modal from '@app/components/UI/Modal/Modal';

interface Props {
	caseToShow: Case;
	open: boolean;
	toggle: () => void;
	onModalArrowClick: (caseToShow: Case, isNext: boolean) => void;
}

const CaseModal: React.FC<Props> = ({
	caseToShow,
	open,
	toggle,
	onModalArrowClick,
}) => {
	return <>
		<Modal
			width="700px"
			title="Case Preview"
			centered
			footer={<>
				<LeftArrow onClick={() => onModalArrowClick(caseToShow, false)} />
				<RightArrow onClick={() => onModalArrowClick(caseToShow, true)} />
			</>}
			open={open}
			onCancel={toggle}
			destroyOnClose
			className="case-modal-wrapper"
		>
			<div className="case-modal">
				<div>
					<img className="case-modal__before" src={`/remote/${caseToShow.before}`} alt="before" />
					<img className="case-modal__after" src={`/remote/${caseToShow.after}`} alt="after" />
				</div>
			</div>
		</Modal>
	</>;
};

export default CaseModal;
