import { ResponsiveObject } from '@ant-design/react-slick';

const getSlidesCount = (count: number, articlesLength: number): number => {
	return articlesLength < count ? articlesLength : count;
};

export const getSettings = (breakpoint: number, count: number, articlesLength: number): ResponsiveObject => {
	return {
		breakpoint,
		settings: {
			slidesToShow: getSlidesCount(count, articlesLength),
		},
	};
};
