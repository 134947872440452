import React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';
import Loading from '@common/react/components/Core/LoadingProvider/Loader';

import imageSkeleton from '@commonTuna/react/images/no-image-available.jpg';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	skeleton?: string;
	withLazy?: boolean;
	imageHiddenClassName?: string;
	loaderContainerClassName?: string;
}

const ImageWithSkeleton: React.FC<Props> = ({
	withLazy,
	skeleton = imageSkeleton,
	imageHiddenClassName = 'hide-colorbox-image',
	loaderContainerClassName = '',
	...props
}) => {
	const [error, setError] = React.useState<boolean>(!props.src);
	const [loading, setLoading] = React.useState(true);
	const className = `${loading ? imageHiddenClassName : ''} ${error ? 'img-error' : ''} ${props.className}`;

	const handleError = (e) => {
		setError(true);
		setLoading(false);
		props.onError && props.onError(e);
	};

	const handleLoad = (e) => {
		props.onLoad && props.onLoad(e);
		setLoading(false);
	};

	React.useEffect(() => {
		error && props.src && setError(false);
	}, [props.src]);

	return <>
		{withLazy
			? (
				<ImageLazy
					{...props}
					onLoad={handleLoad}
					src={error ? skeleton : props.src}
					onError={handleError}
					className={className}
				/>
			)
			: (
				<img
					{...props}
					onLoad={handleLoad}
					alt={props.alt}
					src={error ? skeleton : props.src}
					onError={handleError}
					className={className}
				/>
			)}
		{loading && loaderContainerClassName ? <div className={loaderContainerClassName}>
			<Loading />
		</div> : null}
	</>;
};

export default ImageWithSkeleton;
